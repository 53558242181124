.c-test-month-container {
  width: 100%;
  padding-left: 16px;
  padding-top: 16px;
}

.bar-graph-filters {
  display: flex;
  gap: 8px;
}

.multiple-test-filter {
  width: 360px;
}

@media (min-width: 900px) {
  .c-test-month-container {
    width: 66.667%;
  }
}
